<template>
  <main class="container">
    <ContentHeader
      :title="content_header.title"
      :subTitle="content_header.subTitle"
    />

    <div class="page__body">
      <div class="page__body__header">
        <div>
          <button
            type="button"
            class="button ml-auto page__body__header__button button--black text--capital"
            @click="toggleNavigationModal"
          >
            <span class="d-inline-flex mr-2">add template</span>
            <ion-icon name="add-outline" class="text--regular"></ion-icon>
          </button>
        </div>
      </div>

      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 19%"
            >
              name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 35%"
            >
              event
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 13%"
            >
              state
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 13%"
            >
              status modified
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              option
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  repayment due
                </span>
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                >
                  - Payroll private
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  Unpaid Repayment Due
                </span>
                <span
                  class="table__body__text table__text d-block py-1 table__text--small text--capital"
                >
                  { "accountId": "{ACCOUNT_ID}", "encodedKey": "{ENCODED_KEY}",
                  "totalDue":"{TOTAL_DUE}" }
                </span>
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Not in Use</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Inactive</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Opt-In</span
              >
            </td>

            <td>
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item text--capital">edit</a>
                  <a class="dropdown-item text--capital">activate</a>
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#deleteModal"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  repayment due
                </span>
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                >
                  - Payroll private
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  Unpaid Repayment Due
                </span>
                <span
                  class="table__body__text table__text d-block py-1 table__text--small text--capital"
                >
                  { "accountId": "{ACCOUNT_ID}", "encodedKey": "{ENCODED_KEY}",
                  "totalDue":"{TOTAL_DUE}" }
                </span>
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Not in Use</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Inactive</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Opt-In</span
              >
            </td>

            <td>
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item text--capital">edit</a>
                  <a class="dropdown-item text--capital">activate</a>
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#deleteModal"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  repayment due
                </span>
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                >
                  - Payroll private
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  Unpaid Repayment Due
                </span>
                <span
                  class="table__body__text table__text d-block py-1 table__text--small text--capital"
                >
                  { "accountId": "{ACCOUNT_ID}", "encodedKey": "{ENCODED_KEY}",
                  "totalDue":"{TOTAL_DUE}" }
                </span>
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Not in Use</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Inactive</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Opt-In</span
              >
            </td>

            <td>
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item text--capital">edit</a>
                  <a class="dropdown-item text--capital">activate</a>
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#deleteModal"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  repayment due
                </span>
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                >
                  - Payroll private
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  Unpaid Repayment Due
                </span>
                <span
                  class="table__body__text table__text d-block py-1 table__text--small text--capital"
                >
                  { "accountId": "{ACCOUNT_ID}", "encodedKey": "{ENCODED_KEY}",
                  "totalDue":"{TOTAL_DUE}" }
                </span>
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Not in Use</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Inactive</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Opt-In</span
              >
            </td>

            <td>
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item text--capital">edit</a>
                  <a class="dropdown-item text--capital">activate</a>
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#deleteModal"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  repayment due
                </span>
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                >
                  - Payroll private
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  Unpaid Repayment Due
                </span>
                <span
                  class="table__body__text table__text d-block py-1 table__text--small text--capital"
                >
                  { "accountId": "{ACCOUNT_ID}", "encodedKey": "{ENCODED_KEY}",
                  "totalDue":"{TOTAL_DUE}" }
                </span>
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Not in Use</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Inactive</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Opt-In</span
              >
            </td>

            <td>
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item text--capital">edit</a>
                  <a class="dropdown-item text--capital">activate</a>
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#deleteModal"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  repayment due
                </span>
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                >
                  - Payroll private
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  Unpaid Repayment Due
                </span>
                <span
                  class="table__body__text table__text d-block py-1 table__text--small text--capital"
                >
                  { "accountId": "{ACCOUNT_ID}", "encodedKey": "{ENCODED_KEY}",
                  "totalDue":"{TOTAL_DUE}" }
                </span>
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Not in Use</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Inactive</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Opt-In</span
              >
            </td>

            <td>
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item text--capital">edit</a>
                  <a class="dropdown-item text--capital">activate</a>
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#deleteModal"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  repayment due
                </span>
                <span
                  class="table__body__text table__text py-1 d-block text--capital"
                >
                  - Payroll private
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                >
                  Unpaid Repayment Due
                </span>
                <span
                  class="table__body__text table__text d-block py-1 table__text--small text--capital"
                >
                  { "accountId": "{ACCOUNT_ID}", "encodedKey": "{ENCODED_KEY}",
                  "totalDue":"{TOTAL_DUE}" }
                </span>
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Not in Use</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Inactive</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >Opt-In</span
              >
            </td>

            <td>
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item text--capital">edit</a>
                  <a class="dropdown-item text--capital">activate</a>
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#deleteModal"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary">1</span> to
            <span class="d-inline-block text--primary">10</span> of
            <span>1200</span>
          </div>
          <div
            class="pagination__buttons d-flex align-items-center justify-content-between"
          >
            <button class="button button--sm text--regular">
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              class="button text--regular button--primary text--white text-white button--sm ml-2"
            >
              1
            </button>
            <button class="button text--regular button--sm ml-2">2</button>
            <button class="button text--regular button--sm ml-2">3</button>
            <button class="button text--regular button--sm ml-2">4</button>
            <button class="button text--regular button--sm ml-2">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>

      <!-- Create Notification -->
      <createTaskTemplate
        :show="showCreateNotification"
        @close="toggleNavigationModal"
      />
    </div>
  </main>
</template>
<script>
export default {
  name: "task_templates",
  data() {
    return {
      content_header: {
        title: "Task Templates",
        subTitle:
          "Manage the products offerings that you have available to your customers by creating and editing loan and deposit products.",
      },
      showCreateNotification: false,
    };
  },
  components: {
    createTaskTemplate: () =>
      import(
        /* WebpackChunkName: "createNotification" */ "@/view/pages/sms_templates/tasks/createTaskTemplate.vue"
      ),
  },
  methods: {
    toggleNavigationModal() {
      this.showCreateNotification = !this.showCreateNotification;
    },
  },
};
</script>
